@if (categoryHelpModalComponentService.refreshOperation$ | async) {}
@if (categoryHelpModalComponentService.updateOperationFromSocket$ | async) {}
<form
  [formGroup]="categoryHelpModalComponentService.formGroup"
  (ngSubmit)="categoryHelpModalComponentService.onSubmit()"
>
  <div dougsModalTitle>
    <h6>Aide à la catégorisation</h6>
    <i class="ml-8 fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent="xlarge">
    @if (modalOperationStateService.currentOperation$ | async; as currentOperation) {
      <dougs-operation
        class="mb-16 category-help__operation"
        [disableCategoryHelp]="true"
        [showOperationDetails]="false"
        [changeUrlOnClick]="false"
        [isInOperationListModal]="true"
        [disableRefreshById]="true"
        [operation]="currentOperation"
        [synchronizedAccounts]="synchronizedAccountStateService.allSynchronizedAccounts$ | async"
      />
    } @else {
      <dougs-loader />
    }
    <dougs-form-field>
      <label dougsFormFieldLabel>
        Donnez-nous un maximum d'informations pour vous aider à catégoriser cette opération :&nbsp;
      </label>
      <textarea
        formControlName="description"
        dougsFormFieldControl
        autosize
        placeholder="Ex. : Cette opération correspond à l'assurance de mon ordinateur utilisé pour mon usage personnel."
      ></textarea>
    </dougs-form-field>
    <label dougsFormFieldLabel class="mb-8">
      Ajoutez un justificatif pour nous aider à vous répondre au plus vite (optionnel)
    </label>
    <dougs-operation-files
      [inCategorizationHelp]="true"
      [operation]="modalOperationStateService.currentOperation$ | async"
    />
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    @if (categoryHelpModalComponentService.data.task) {
      <dougs-button dougsModalClose>Terminer</dougs-button>
    } @else {
      <dougs-button
        type="submit"
        [disabled]="
          categoryHelpModalComponentService.formGroup.invalid || categoryHelpModalComponentService.isSending$()
        "
      >
        Envoyer ma demande
      </dougs-button>
    }
  </div>
</form>
