import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  FileInputComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SourceDocumentPillComponent,
  TrackByPipe,
} from '@dougs/ds';
import { ModalOperationStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { OperationFilesComponent } from '../../components/operations/operation/operation-files/operation-files.component';
import { OperationComponent } from '../../components/operations/operation/operation.component';
import { CategoryHelpModalComponentService } from '../../services/category-help-modal.component.service';
import { OperationEcommerceComponentService } from '../../services/operation-ecommerce.component.service';

@Component({
  selector: 'dougs-category-help-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    AutosizeModule,
    FileInputComponent,
    PanelInfoComponent,
    LoaderComponent,
    OperationComponent,
    SourceDocumentPillComponent,
    TrackByPipe,
    OperationFilesComponent,
  ],
  templateUrl: './category-help-modal.component.html',
  styleUrl: './category-help-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CategoryHelpModalComponentService,
    OperationEcommerceComponentService,
    {
      provide: OPERATION_STATE_TOKEN,
      useExisting: ModalOperationStateService,
    },
  ],
})
export class CategoryHelpModalComponent {
  constructor(
    public readonly categoryHelpModalComponentService: CategoryHelpModalComponentService,
    public readonly modalOperationStateService: ModalOperationStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
  ) {}
}
